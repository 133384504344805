import { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';

import { useAuthentication } from 'features/auth/context/AuthContext';
import useChains from 'features/chainSwitching/hooks/useChain';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcModal from 'shared/NcModal/NcModal';

const SwitchWrongNetwork = () => {
  const { marketPlaceDetail } = useAuthentication();
  const { chainId, isAuthenticated } = useMoralis();
  const [invalidChainModal, setInvalidChainModal] = useState(false);
  const { switchCustomNetwork } = useChains();
  useEffect(() => {
    if (marketPlaceDetail?.availableChains?.length > 0 && isAuthenticated) {
      if (!marketPlaceDetail?.availableChains?.includes(chainId)) {
        setInvalidChainModal(true);
      } else {
        setInvalidChainModal(false);
      }
    }
  }, [marketPlaceDetail, chainId, isAuthenticated]);
  const renderWrongNetworkPopup = () => {
    return (
      <div>
        <span className='text-md flex justify-center'>
          Please switch network
        </span>
        <div className='mt-2 flex justify-center'>
          <ButtonPrimary
            onClick={() =>
              switchCustomNetwork(marketPlaceDetail?.availableChains?.[0])
            }
          >
            Switch Network
          </ButtonPrimary>
        </div>
      </div>
    );
  };
  return (
    <NcModal
      renderTrigger={() => null}
      renderContent={renderWrongNetworkPopup}
      isOpenProp={invalidChainModal}
      modalTitle='Wrong Network'
      contentExtraClass='max-w-lg'
      disableCrossIcon
    />
  );
};
export default SwitchWrongNetwork;
